import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

class NotFound extends Component {
  render() {
    return (
      <Layout>
        <div className="container pt-12 px-0 mb-12">
          <div className="display-3 px-2 px-sm-0">Lost Adventurer Corner</div>
          <p className="h4 fw-regular px-2 px-sm-0 py-12">You've met a deadend. <span className="text-primary"><Link to="/">Start Over</Link></span></p>
        </div>
      </Layout>
    )
  }
}

export default NotFound
